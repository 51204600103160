<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 px-10 mb-8 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-app-bar-title>Create Account</v-app-bar-title>
                        </v-app-bar>

                        <v-card-text>
                            <p>Choose a name for the new account. You can change it later.</p>
                        </v-card-text>

                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2">
                            <!-- <v-select v-model="newAccountType" :items="accountTypeChoices" label="Account type"/> -->
                            <v-text-field v-model="newAccountName" label="Account name" hint="This will be displayed on your statements and other correspondence." ref="newAccountNameInput" outlined dense></v-text-field>
                            <!-- TODO: allow the user to select an account type from choices of individual, professional, household, team, company, non-profit, or government -->
                            <p class="mb-0 mt-6">What kind of account is it?</p>
                            <v-list v-if="accountTypeChoices.length > 0">
                                <v-list-item-group v-model="newAccountTypeIndex" :color="primaryColor">
                                <v-list-item v-for="(item, idx) in accountTypeChoices" :key="idx">
                                    <v-list-item-icon>
                                        <font-awesome-icon :icon="item.icon" fixed-width></font-awesome-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.text }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ item.description }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                </v-list-item-group>
                            </v-list>

                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" :style="primaryButtonStyle" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <!-- <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn> -->
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
    data: () => ({
        submitFormTimestamp: null,
        newAccountName: null,
        newAccountTypeIndex: 0, // start with the individual account type selected
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            accountTypeChoices: (state) => state.accountTypeChoices,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isNewItemFormComplete() {
            return typeof this.newAccountName === 'string' && this.newAccountName.length > 0
            && typeof this.newAccountType === 'string' && this.newAccountType.length > 0;
        },
        newAccountType() {
            if (!Number.isInteger(this.newAccountTypeIndex) || !Array.isArray(this.accountTypeChoices)) {
                return null;
            }
            if (this.newAccountTypeIndex < 0 || this.newAccountTypeIndex >= this.accountTypeChoices.length) {
                return null;
            }
            return this.accountTypeChoices[this.newAccountTypeIndex].value;
        },
    },
    methods: {
        async createItem() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createItem: true });
                const item = {
                    name: this.newAccountName,
                    type: this.newAccountType,
                };
                const response = await this.$client.site(this.$route.params.brandprofile).user(this.session.userId).account.create(item);
                if (response?.isCreated && response.id) {
                    // this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.$router.replace({ name: 'account-dashboard', params: { brandprofile: this.$route.params.brandprofile, accountId: response.id }, query: { t: Date.now() } });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
    },
    mounted() {
        // this.loadOrganization();
        this.$nextTick(() => {
            setTimeout(() => { this.$activateInput('newAccountNameInput'); }, 1);
        });
    },
};
</script>
